<template>
    <b-card no-body class="tab-layout">
        <h5
            class="text-center mb-0 px-3 py-3 over-hover"
            v-if="showMainHeader && activeTableRef && activeTableRef.table"
        >
            <span v-html="activeTableRef.table.header"></span>
        </h5>

        <b-tabs v-model="activeTab" justified card>
            <b-tab :key="table" v-for="(table, index) in tables">
                <template #title>
                    {{ titles[index] }}
                </template>
                <keep-alive>
                    <platon-table
                        v-if="isTabActive(index)"
                        ref="tables"
                        :table-name="table"
                        @tableLoaded="(t) => onTableLoaded(index, t)"
                        @onFiltersChange="(filters) => onTableFilterChanged(index, filters)"
                        :table-filters="getFiltersFor(index)"
                        :external-filters="filtersAndButtons"
                        :show-header="showHeader"
                        :use-external-buttons="useExternalButtons"
                        :use-external-filters="useExternalFilters"
                        :control-buttons="true"
                        :pagination-enabled="true"
                        :show-filters="true"
                        :draw="index === activeTab"
                    />
                </keep-alive>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { isObjDiff } from "@Platon/core/helpers"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { DevLog } from "@Platon/const"

const separateFilterNames = ["_page", "_level"]

/**
 * @property {Vue.Component[]} $refs.tables
 */
export default {
    name: "MultiTable",

    mixins: [NavigationMixin],

    props: {
        tables: {
            type: Array,
            required: true
        },

        titles: {
            type: Array,
            required: true
        },

        showHeader: {
            type: Boolean,
            default: false
        },

        showMainHeader: {
            type: Boolean,
            default: false
        },

        separateFilters: {
            type: Boolean,
            default: false
        },

        mainTable: {
            type: String,
            required: true
        },

        // autoRouting: {
        // 	type: Boolean,
        // 	default: false
        // },

        useExternalButtons: {
            type: Boolean,
            default: false
        },

        useExternalFilters: {
            type: Boolean,
            default: true
        }
    },

    data() {
        let mainTabIndex = this.tables.findIndex((t) => t === this.mainTable)
        let openTabFromRoute = this.tables.findIndex((t) => t === this.$route.query.__tab)

        let activeTab = openTabFromRoute !== -1 ? openTabFromRoute : mainTabIndex

        let filters = { ...this.$route.query }

        return {
            filters,
            activeTab: activeTab,
            mainTabIndex: mainTabIndex,
            filtersAndButtons: [],
            mainTableRef: null,
            activeTableRef: null
        }
    },

    mounted() {
        this.registerLinkHandler(this.tableLinkHandler)
    },

    beforeDestroy() {
        this.unregisterLinkHandler()
    },

    methods: {
        /**
         * @param {Number} index
         * @param {Vue.Component} table
         */
        onTableLoaded(index, table) {
            this.updateMainHeader()

            if (this.mainTabIndex !== index) {
                return
            }

            this.mainTableRef = table
            this.filtersAndButtons = table.filtersAndButtons
        },

        /**
         * @param {number} index - table index
         */
        getFiltersFor(index) {
            let filters = { ...(index === this.mainTabIndex ? this.mainFilters : this.filters) }
            let tableName = this.tables[index]

            if (this.separateFilters) {
                for (let filter of separateFilterNames) {
                    // _level_farmers, _page_farmers
                    if (filters[`${filter}_${tableName}`]) {
                        filters[filter] = filters[`${filter}_${tableName}`]
                    }
                }
            }

            return filters
        },

        onFiltersChange(filters) {
            // respect current tab active tab
            this.changeFiltersForTab(this.activeTab, filters)
        },

        onTableFilterChanged(tabIndex, filters) {
            if (tabIndex === this.activeTab) {
                this.changeFiltersForTab(tabIndex, filters)
            }
        },

        /**
         * @param {number} tabIndex
         * @param {object} filters
         */
        changeFiltersForTab(tabIndex, filters) {
            DevLog("Table filters changed", filters)

            let newFilters = { ...filters }
            let activeTableName = this.tables[tabIndex]

            if (this.separateFilters) {
                for (let filter of separateFilterNames) {
                    if (newFilters[filter]) {
                        newFilters[`${filter}_${activeTableName}`] = newFilters[filter]

                        delete newFilters[filter]
                    }
                }
            }

            this.handleFilterChange({ ...newFilters, __tab: activeTableName })
        },

        handleFilterChange(filters) {
            if (Object.keys(filters).length === 0) return

            if (isObjDiff(filters, this.filters)) {
                this.$set(this, "filters", filters)

                this.changeActiveTabIfNeeded()

                this.$router.push({
                    query: filters
                })
            }
        },

        changeActiveTabIfNeeded() {
            let currentTab = this.activeTab
            let tabFromFilters = this.tables.findIndex((t) => t === this.filters.__tab)

            if (currentTab !== tabFromFilters) {
                this.activeTab = tabFromFilters
            }
        },

        /**
         * @param {Route} route
         */
        tableLinkHandler(route) {
            if (route.name === "table" && route.params.name === this.activeTabName) {
                this.onFiltersChange(route.query)

                return true
            }
        },

        updateMainHeader() {
            this.$nextTick(() => {
                this.activeTableRef = this.$refs.tables.find((t) => t.tableName === this.activeTabName)
            })
        },

        isTabActive(index) {
            return index === this.activeTab || index === this.mainTabIndex
        }
    },

    computed: {
        /**
         * Using to prevent loading main table unnecessarily
         */
        mainFilters() {
            if (this.filters.__tab !== this.mainTable) {
                return { ...this.filters, __tab: this.mainTable }
            } else {
                return { ...this.filters }
            }
        },

        activeTabName() {
            return this.tables[this.activeTab]
        }
    },

    watch: {
        activeTab(active) {
            // respect current active tab
            this.handleFilterChange({ ...this.filters, __tab: this.tables[active] })

            this.updateMainHeader()
        },

        $route(current, old) {
            // respect tab from route
            this.onFiltersChange({ __tab: this.tables[this.activeTab], ...current.query })
        }
    }
}
</script>
